import "vite/modulepreload-polyfill";
import "lazyvids";

// Set modal app states on the window so they can be accessed from anywhere
window.appState = {
  cartDrawer: false,
  nav: false,
  predictiveSearch: false,
};

const header = document.querySelector(".header__desktop-nav");
const sections = [...document.querySelectorAll("[data-section-theme]")];

let prevYPosition = 0;
let direction = "up";

const options = {
  root: null,
  rootMargin: `${header.offsetHeight * -1}px`,
  threshold: 0,
};

const getTargetSection = (entry) => {
  const index = sections.findIndex((section) => section == entry.target);

  if (index >= sections.length - 1) {
    return entry.target;
  } else {
    return sections[index + 1];
  }
};

const updateColors = (target) => {
  const theme = target.dataset.sectionTheme;
  document.documentElement.style.setProperty("--main-nav", theme);
};

const shouldUpdate = (entry) => {
  if (direction === "down" && !entry.isIntersecting) {
    return true;
  }

  if (direction === "up" && entry.isIntersecting) {
    return true;
  }

  return false;
};

const onIntersect = (entries, observer) => {
  entries.forEach((entry) => {
    if (window.scrollY > prevYPosition) {
      direction = "down";
    } else {
      direction = "up";
    }

    prevYPosition = window.scrollY;

    const target =
      direction === "down" ? getTargetSection(entry) : entry.target;

    if (shouldUpdate(entry)) {
      updateColors(target);
    }
  });
};

const observer = new IntersectionObserver(onIntersect, options);

sections.forEach((section) => {
  observer.observe(section);
});

class CardProductMedia extends HTMLElement {
  constructor() {
    super();
    this.infoToggle = this.querySelector("[data-info-toggle]");
    this.infoPanel = this.querySelector("[data-info-panel]");
  }

  connectedCallback() {
    this.init();
  }

  init() {
    if (this.dataset.cardProductMediaInitialised) return;

    this.dataset.cardProductMediaInitialised = true;
    if (!this.infoToggle || !this.infoPanel) return;

    this.infoToggle.setAttribute("aria-expanded", "false");
    this.infoToggleSpan = this.infoToggle.querySelector("span");
    this.infoToggleSpan.textContent = "i";

    this.infoToggle.addEventListener("click", () => {
      this.infoPanel.classList.toggle("active");
      this.infoToggle.classList.toggle("active");

      let expanded = this.infoToggle.getAttribute("aria-expanded") === "true";
      this.infoToggle.setAttribute("aria-expanded", !expanded);
      this.infoToggleSpan.textContent = expanded ? "i" : "❌";
    });
  }
}

customElements.define("card-product-media", CardProductMedia);

class Accordion extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.init();
  }

  handleClick(event) {
    const accordion = event.currentTarget;
    const currentToggle = event.target.closest(".accordion__toggle");
    if (!currentToggle) return;

    const panelId = currentToggle.getAttribute("aria-controls");
    const currentPanel = accordion.querySelector(`#${panelId}`);
    const multiplePanes = currentToggle.getAttribute("aria-multiselectable");
    const toggles = accordion.querySelectorAll(".accordion__toggle");
    const panels = accordion.querySelectorAll(".accordion__panel");

    if (multiplePanes !== "true") {
      toggles.forEach((toggle) => {
        if (toggle !== currentToggle) {
          toggle.setAttribute("aria-expanded", false);
        }
      });
      panels.forEach((panel) => {
        if (panel === currentPanel) {
          const ariaState = currentToggle.getAttribute("aria-expanded");
          if (ariaState === "true") {
            currentToggle.setAttribute("aria-expanded", false);
            panel.style.height = 0;
          } else {
            currentToggle.setAttribute("aria-expanded", true);
            panel.style.height = `${panel.scrollHeight}px`;
          }
        } else {
          panel.style.height = 0;
        }
      });
    } else if (multiplePanes === "true") {
      if (currentToggle.getAttribute("aria-expanded") === "true") {
        currentToggle.setAttribute("aria-expanded", false);
        currentPanel.style.height = 0;
      } else {
        currentToggle.setAttribute("aria-expanded", true);
        currentPanel.style.height = `${currentPanel.scrollHeight}px`;
      }
    }
  }

  init() {
    if (this.dataset.accordionInitialised) return;

    const panels = this.querySelectorAll(".accordion__panel");
    panels.forEach((panel) => {
      if (panel.classList.contains("show-panel")) {
        panel.style.height = `${panel.scrollHeight}px`;
      }
    });

    this.addEventListener("click", this.handleClick.bind(this));
    this.dataset.accordionInitialised = true;
  }
}

customElements.define("accordion-component", Accordion);

class ModalDrawer extends HTMLElement {
  constructor() {
    super();
    this.triggerId = this.getAttribute("data-modal-trigger-id");
    this.modalTriggers = document.querySelectorAll(
      `[data-modal-trigger-id="${this.triggerId}"]`,
    );

    this.modalDrawerIsOpen = false;
  }

  connectedCallback() {
    this.modalDrawerBackdrop = this.querySelector(
      "[data-modal-drawer-backdrop]",
    );
    this.modalDrawerCloseButton = this.querySelector(
      "[data-modal-drawer-close]",
    );

    this.modalTriggers.forEach((trigger) => {
      trigger.addEventListener("click", this.open.bind(this));
    });

    this.modalDrawerBackdrop.addEventListener("click", this.close.bind(this));
    this.modalDrawerCloseButton.addEventListener(
      "click",
      this.close.bind(this),
    );
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
  }

  open() {
    this.modalDrawerIsOpen = true;

    this.classList.add("active");
    document.body.classList.add("modal-drawer-open");

    this.addEventListener(
      "transitionend",
      () => {
        const containerToTrapFocusOn = this;
        const focusElement = this.modalDrawerCloseButton;
        trapFocus(containerToTrapFocusOn, focusElement);
      },
      { once: true },
    );

    this.lockScroll();
  }

  close() {
    event.stopPropagation();
    this.modalDrawerIsOpen = false;
    this.classList.remove("active");
    document.body.classList.remove("modal-drawer-open");

    this.unlockScroll();
  }

  lockScroll() {
    const scrollY = window.scrollY;
    document.documentElement.style.setProperty("--scroll-y", `${scrollY}px`);
    document.body.style.position = "fixed";
    document.body.style.width = "100%";
    document.body.style.top = `-${scrollY}px`;
  }

  unlockScroll() {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.width = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }

  handleKeyDown(event) {
    if (event.key === "Escape" || event.key === "Esc" || event.keyCode === 27) {
      if (this.modalDrawerIsOpen) {
        this.close();
      }
    }
  }
}

customElements.define("modal-drawer", ModalDrawer);
